import { Button, Form } from 'react-bootstrap';
import styled from 'styled-components';

import { font } from '../../atoms/typography';

/*
 * CaptureContainer - the container styles for the email capture form.
 */
export const CaptureContainer = styled.div`
  /* Props */
  /* display properties */
  display: flex;
  flex-direction: row;
  background-color: ${props => (props.shadow ? '#fff' : '')};
  border-radius: ${props => (props.shadow ? '8px' : '7px')};
  box-shadow: ${props =>
    props.shadow
      ? '0px 4px 8px -2px rgba(19, 48, 205, 0.1), 0px 2px 4px -2px rgba(19, 48, 205, 0.06)'
      : ''};
  /* padding and margin */
  padding: ${props =>
    props.shadow ? '1.25rem' : props.position ? '0' : '1rem'};
  margin-bottom: ${props => (props.position ? '7rem' : '2rem')};
  /*  alignment */
  justify-content: center;
  align-items: center;
  gap: 20px;
  @media screen and (max-width: 991px) {
    margin-top: 32px;
    text-align: center;
  }
  @media screen and (max-width: 500px) {
    flex-direction: column;
    margin-bottom: 2rem;
  }
  .section {
    width: 100%;
    max-width: 1200px;
    /*  */
    border-radius: 7px;
  }
  .header {
    width: 100%;
    h6 {
      ${font('display', 'sm', '700')}
      color: #171E2A;
      margin-bottom: 16px;
    }
    p {
      ${font('text', 'lg', '400')}
      color: #171e2a;
      margin-bottom: 16px;
    }
  }
  .column {
    display: flex;
    flex-direction: column;
    /* width */
    max-width: 1200px;
    width: 100%;
  }
  .btn {
    margin: 1rem;
    width: ${props => (props.shadow ? '100%' : '')};
    /* btn font styling */
    font-weight: 400;
  }
  /* Form-Section */
  .form-section {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    flex-direction: ${props => (props.shadow ? 'column' : 'row')};
    @media screen and (max-width: 1200px) {
      margin: 0 auto;
      max-width: 700px;
    }
    @media screen and (max-width: 550px) {
      flex-direction: column;
      width: 100%;
    }
  }
  .form-header {
    /* max-width: 500px; */
    /* font form header */
    ${font('text', 'xl', '400')}
  }
`;

/*
 * CaptureButton - the submit button for the email capture form.
 */
export const CaptureButton = styled(Button)`
  /* padding / margin / sizing */
  height: 43px;
  ${font('text', 'md', '700')}

  @media screen and (max-width: 550px) {
    width: 100%;
  }
  @media screen and (max-width: 991px) {
    max-width: 500px;
  }
  /* Coloring */
  background-color: ${props =>
    props.color
      ? 'var(--primary-color)'
      : 'var(--secondary-color)'}; //shopmonkey blue
  color: white;
  /* Font styling */
  &:hover {
    background-color: ${props =>
      props.color ? '#203ac7' : '#00986c'}!important;
    border-color: ${props => (props.color ? '#203ac7' : '#00986c')};
  }
`;

/*
 * BlogFormInput - the email input section of the form.
 */
export const BlogFormInput = styled(Form.Control)`
  @media screen and (min-width: 500px) {
    max-width: 500px;
  }
  max-width: 500px;
  .form-control {
    background-color: transparent;
    height: 46px;
    &.dark-input {
      color: #1d2030;
      border-color: #fff;
      &:focus {
        color: #fff;
        border-color: #fff;
      }
    }
  }
`;

export const FormContainer = styled.div`
  display: flex;
  max-width: 1200px;
`;
